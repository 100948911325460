<template>
  <v-card class="d-flex flex-column mt-5 mb-5" v-if="volunteer">
    <v-card-title class="d-flex align-center">
      <span
        v-text="volunteer.first_name + ' ' + volunteer.last_name"
        style="color: #472583"
      />
      <icon-button
        :onClicked="onEditName"
        icon="mdi-pencil"
        text="Edit Name and Image"
        v-if="profile._id == volunteer._id"
      />
    </v-card-title>
    <v-card-subtitle class="mt-4 d-flex align-center">
      <span class="font-16 app-bold-font dark-font-color mr-2">About Me</span>
      <icon-button
        :onClicked="onEditAboutMe"
        icon="mdi-pencil"
        text="Edit About Me"
        v-if="profile._id == volunteer._id"
      />
    </v-card-subtitle>
    <v-card-text>
      <span v-text="volunteer.about_me" class="text-block" />
    </v-card-text>
    <v-card-subtitle class="mt-4 d-flex align-center">
      <span class="font-16 app-bold-font dark-font-color mr-3">Causes</span>
      <icon-button
        :onClicked="onEditCause"
        icon="mdi-pencil"
        text="Edit Causes"
        v-if="profile._id == volunteer._id"
      />
    </v-card-subtitle>
    <v-card-text style="display: flex; flex-wrap: wrap;">
      <site-icon
        :name="icon"
        type="Cause"
        v-for="(icon, key) in volunteer.causes"
        :key="key"
        class="mr-3 mt-3"
      />
    </v-card-text>
    <v-card-subtitle class="mt-4 d-flex align-center">
      <span class="font-16 app-bold-font dark-font-color mr-3">Interests</span>
      <icon-button
        :onClicked="onEditInterest"
        icon="mdi-pencil"
        text="Edit Interests"
        v-if="profile._id == volunteer._id"
      />
    </v-card-subtitle>
    <v-card-text class="d-flex flex-wrap">
      <site-icon
        :name="icon"
        type="Interest"
        v-for="(icon, key) in volunteer.interests"
        :key="key"
        class="mr-3 mt-3"
      />
    </v-card-text>
    <v-card-subtitle class="d-flex align-center">
      <span class="font-16 app-bold-font dark-font-color">Information</span>
      <icon-button
        :onClicked="onEditInformation"
        icon="mdi-pencil"
        text="Edit Information"
        v-if="profile._id == volunteer._id"
      />
    </v-card-subtitle>
    <v-card-text>
      <div class="d-flex align-center" v-if="volunteer.phone">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              color="#0a5b8a"
              size="14"
            >
              mdi-phone-classic
            </v-icon>
          </template>
          <span>Phone</span>
        </v-tooltip>
        {{ formatPhoneNumber(volunteer.phone) }}
        <v-tooltip bottom v-if="volunteer._id == profile._id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-2"
              x-small
              v-bind="attrs"
              v-on="on"
              color="#0a5b8a"
              @click="editPhoneAudience"
              ><v-icon>{{
                profile.phone_share_privacy
                  ? shareIcons[profile.phone_share_privacy]
                  : "mdi-lock"
              }}</v-icon></v-btn
            >
          </template>
          <span>{{
            profile.phone_share_privacy
              ? profile.phone_share_privacy
              : "Only me"
          }}</span>
        </v-tooltip>
      </div>
      <div class="d-flex align-center mt-2" v-if="volunteer.email">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              color="#0a5b8a"
              size="14"
              >mdi-email</v-icon
            >
          </template>
          <span>Email</span>
        </v-tooltip>
        {{ volunteer.email }}
        <v-tooltip bottom v-if="profile._id == volunteer._id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-2"
              x-small
              v-bind="attrs"
              v-on="on"
              color="#0a5b8a"
              @click="editEmailAudience"
              ><v-icon>{{
                profile.email_share_privacy
                  ? shareIcons[profile.email_share_privacy]
                  : "mdi-lock"
              }}</v-icon></v-btn
            >
          </template>
          <span>{{
            profile.email_share_privacy
              ? profile.email_share_privacy
              : "Only me"
          }}</span>
        </v-tooltip>
      </div>
      <div class="d-flex align-center mt-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              color="#0a5b8a"
              size="14"
            >
              mdi-account-group
            </v-icon>
          </template>
          <span>Age group</span>
        </v-tooltip>
        {{ volunteer.age_group }}
      </div>
    </v-card-text>
    <v-card-subtitle
      v-if="
        volunteer.address ||
          volunteer.address2 ||
          volunteer.city ||
          volunteer.state ||
          volunteer.zip
      "
      class="d-flex align-center"
    >
      <span class="font-16 app-bold-font dark-font-color">Location</span>
      <icon-button
        :onClicked="onEditLocation"
        icon="mdi-pencil"
        text="Edit Location"
        v-if="profile._id == volunteer._id"
      />
    </v-card-subtitle>
    <v-card-text
      class="mb-5"
      v-if="
        volunteer.address ||
          volunteer.address2 ||
          volunteer.city ||
          volunteer.state ||
          volunteer.zip
      "
    >
      <div class="d-flex align-center mt-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              color="#0a5b8a"
              size="14"
              >mdi-map-marker</v-icon
            >
          </template>
          <span>Address</span>
        </v-tooltip>
        {{
          `${volunteer.address}, ${
            volunteer.address2 ? volunteer.address2 + ", " : " "
          }${volunteer.city}, ${volunteer.state} ${volunteer.zip}`
        }}
        <v-tooltip bottom v-if="profile._id == volunteer._id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-2"
              x-small
              v-bind="attrs"
              v-on="on"
              color="#0a5b8a"
              @click="editAddressAudience"
              ><v-icon>{{
                profile.address_share_privacy
                  ? shareIcons[profile.address_share_privacy]
                  : "mdi-lock"
              }}</v-icon></v-btn
            >
          </template>
          <span>{{
            profile.address_share_privacy
              ? profile.address_share_privacy
              : "Only me"
          }}</span>
        </v-tooltip>
      </div>
    </v-card-text>
    <v-card-subtitle class="d-flex align-center">
      <span class="font-16 app-bold-font dark-font-color">Availabilities</span>
      <icon-button
        :onClicked="onEditAvailabilities"
        icon="mdi-pencil"
        text="Edit Availabilities"
        v-if="profile._id == volunteer._id"
      />
    </v-card-subtitle>
    <v-card-text class="mb-5">
      <div class="d-flex mt-2 flex-column">
        <div
          v-for="(availability, index) in volunteer.availabilities.filter(
            (a) => a.morning || a.afternoon || a.evening
          )"
          :key="index"
          class="mb-3 d-flex align-center"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                color="#0a5b8a"
                size="14"
                >mdi-calendar-clock</v-icon
              >
            </template>
            <span>Available time</span>
          </v-tooltip>
          <div class="app-bold-font">
            {{ availability.day }}
          </div>
          <span class="ml-3 font-16">
            {{ getScheduleTime(availability) }}
          </span>
        </div>
      </div>
    </v-card-text>
    <volunteer-profile-edit-name
      :dialog="editNameDialog"
      :onClose="onCloseEditDialog"
      v-if="editNameDialog"
    />
    <volunteer-profile-edit-about-me
      :dialog="editAboutMeDialog"
      :onClose="onCloseEditDialog"
      v-if="editAboutMeDialog"
    />
    <volunteer-profile-edit-information
      :dialog="editInformationDialog"
      :onClose="onCloseEditDialog"
      v-if="editInformationDialog"
    />
    <volunteer-profile-edit-location
      :dialog="editLocationDialog"
      :onClose="onCloseEditDialog"
      v-if="editLocationDialog"
    />
    <volunteer-profile-causes-dialog
      :dialog="editCauseDialog"
      :onClose="onCloseEditDialog"
      v-if="editCauseDialog"
    />
    <volunteer-profile-interests-dialog
      :dialog="editInterestDialog"
      :onClose="onCloseEditDialog"
      v-if="editInterestDialog"
    />
    <volunteer-profile-availabilities-dialog
      :dialog="editAvailabilities"
      :onClose="onCloseEditDialog"
      v-if="editAvailabilities"
    />
    <volunteer-profile-edit-audience
      :dialog="audienceDialog"
      :type="audienceType"
      :onClose="onCloseEditDialog"
      v-if="audienceDialog && audienceType"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import IconButton from "@/components/common/IconButton.vue";
import VolunteerProfileEditName from "./edit/VolunteerProfileEditName.vue";
import VolunteerProfileEditAboutMe from "./edit/VolunteerProfileEditAboutMe.vue";
import VolunteerProfileEditInformation from "./edit/VolunteerProfileEditInformation.vue";
import VolunteerProfileEditLocation from "./edit/VolunteerProfileEditLocation.vue";
import VolunteerProfileCausesDialog from "./edit/VolunteerProfileCausesDialog.vue";
import VolunteerProfileInterestsDialog from "./edit/VolunteerProfileInterestsDialog.vue";
import VolunteerProfileAvailabilitiesDialog from "./edit/VolunteerProfileAvailabilitiesDialog.vue";
import SiteIcon from "@/components/common/SiteIcon.vue";
import VolunteerProfileEditAudience from "./edit/VolunteerProfileEditAudience";
export default {
  components: {
    IconButton,
    VolunteerProfileEditName,
    VolunteerProfileEditAboutMe,
    VolunteerProfileEditInformation,
    VolunteerProfileEditLocation,
    VolunteerProfileCausesDialog,
    VolunteerProfileInterestsDialog,
    VolunteerProfileAvailabilitiesDialog,
    SiteIcon,
    VolunteerProfileEditAudience,
  },
  data() {
    return {
      editNameDialog: false,
      editAboutMeDialog: false,
      editInformationDialog: false,
      editLocationDialog: false,
      editCauseDialog: false,
      editInterestDialog: false,
      editScheduleDialog: false,
      editAvailabilities: false,
      audienceDialog: false,
      audienceType: null,
      shareIcons: {
        "Only me": "mdi-lock",
        "Only agencies I fanned": "mdi-domain",
        "Only followed volunteers": "mdi-account-multiple",
        "All followed agencies and volunteers": "mdi-account-group",
        Public: "mdi-earth",
      },
    };
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      volunteer: "volunteer/getVolunteer",
    }),
  },
  methods: {
    ...mapActions("volunteer", {
      copyVolunteerFromProfile: "copyFromProfile",
    }),
    onEditName() {
      this.editNameDialog = true;
    },
    onEditAboutMe() {
      this.editAboutMeDialog = true;
    },
    onEditCause() {
      this.editCauseDialog = true;
    },
    onEditInterest() {
      this.editInterestDialog = true;
    },
    onEditSchedule() {
      this.editScheduleDialog = true;
    },
    onEditInformation() {
      this.editInformationDialog = true;
    },
    onEditLocation() {
      this.editLocationDialog = true;
    },
    onEditAvailabilities() {
      this.editAvailabilities = true;
    },
    getDay(schedule) {
      return schedule.split(" ")[0];
    },
    getScheduleTime(schedule) {
      let str = schedule.morning ? "Morning" : "";
      str = !schedule.afternoon ? str : str ? str + ", Afternoon" : "Afternoon";
      str = !schedule.evening ? str : str ? str + ", Evening" : "Evening";

      return str;
    },
    onCloseEditDialog() {
      this.editNameDialog = false;
      this.editAboutMeDialog = false;
      this.editInformationDialog = false;
      this.editLocationDialog = false;
      this.editCauseDialog = false;
      this.editInterestDialog = false;
      this.editAvailabilities = false;
      this.audienceDialog = false;
      this.copyVolunteerFromProfile();
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    editPhoneAudience() {
      this.audienceType = "phone";
      this.audienceDialog = true;
    },
    editEmailAudience() {
      this.audienceType = "email";
      this.audienceDialog = true;
    },
    editAddressAudience() {
      this.audienceType = "address";
      this.audienceDialog = true;
    },
  },
};
</script>
<style scoped></style>
